<script setup lang="ts">
import {
	Dialog,
	DialogTrigger,
	DialogContent,
	DialogHeader,
	DialogTitle,
	Button,
	IconButton,
	useToast,
} from '@laam/ui/base';
import {
	PhShareFat,
	PhWhatsappLogo,
	PhPinterestLogo,
	PhFacebookLogo,
} from '@phosphor-icons/vue';
import { useScreenSize } from '@laam/lib/utils';
import { useRoute } from 'vue-router';

const { toast } = useToast();

const open = useState('open', () => false);
const route = useRoute();
const screen = useScreenSize();

const linkFacebook = computed(() => {
	const l =
		'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Flaam.pk' +
		encodeURIComponent(route.path) +
		'&amp;src=sdkpreparse';
	return l;
});
const linkPinterest = computed(() => {
	return (
		'http://pinterest.com/pin/create/button/?url=' +
		encodeURIComponent(route.path) +
		'&description=' +
		encodeURIComponent('Check this out!!')
	);
});
const linkWhatsapp = computed(() => {
	if (screen.device.value.isMobile)
		return 'whatsapp://send?text=Check this out ' + window.location.href;
	return (
		'https://web.whatsapp.com://send?text=Check this out ' +
		window.location.href
	);
});

const copyLink = () => {
	navigator.clipboard.writeText(window.location.href);
	toast({
		title: 'Link copied to clipboard!',
		variant: 'info',
		duration: 3000,
	});
	open.value = false;
};
</script>
<template>
	<Dialog :open="open" @update:open="open = !open">
		<DialogTrigger :as-child="true">
			<IconButton size="nav-button" variant="icon">
				<PhShareFat />
			</IconButton>
		</DialogTrigger>
		<DialogContent>
			<DialogHeader>
				<DialogTitle>Share it on</DialogTitle>
				<div class="flex flex-col gap-md mt-lg">
					<NuxtLink :to="linkFacebook" target="_blank" class="w-full">
						<Button variant="secondary" class="w-full">
							<PhFacebookLogo weight="fill" />
							<span>Facebook</span>
						</Button>
					</NuxtLink>

					<NuxtLink :to="linkWhatsapp" target="_blank" class="w-full">
						<Button variant="secondary" class="w-full">
							<PhWhatsappLogo weight="fill" />
							<span>Whatsapp</span>
						</Button>
					</NuxtLink>

					<NuxtLink :to="linkPinterest" target="_blank" class="w-full">
						<Button variant="secondary" class="w-full">
							<PhPinterestLogo weight="fill" />
							<span>Pinterest</span>
						</Button>
					</NuxtLink>

					<div class="text-center">OR</div>

					<Button variant="primary" class="w-full" @click="copyLink">
						Copy link
					</Button>
				</div>
			</DialogHeader>
		</DialogContent>
	</Dialog>
</template>
