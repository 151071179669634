import { ref, onMounted, onUnmounted } from 'vue';

// Define breakpoints for different screen sizes
const BREAKPOINTS = {
	mobile: 640,
	tab: 768,
	desktop: 1024,
	largeDesktop: 1280,
};

// Function to get current screen size based on window width
function getCurrentScreenSize(width: number) {
	if (width < BREAKPOINTS.mobile) {
		return { isMobile: true, isTab: false, isDesktop: false };
	} else if (width < BREAKPOINTS.tab) {
		return { isMobile: false, isTab: true, isDesktop: false };
	} else if (width < BREAKPOINTS.desktop) {
		return { isMobile: false, isTab: false, isDesktop: true };
	} else {
		return { isMobile: false, isTab: false, isDesktop: false };
	}
}

export default function useScreenSize() {
	const device = ref({ isMobile: false, isTab: false, isDesktop: false });

	// Update device when window is resized
	const handleResize = () => {
		if (typeof window !== 'undefined') {
			device.value = getCurrentScreenSize(window.screen.width);
		}
	};

	onMounted(() => {
		if (typeof window !== 'undefined') {
			device.value = getCurrentScreenSize(window.screen.width);
			window.addEventListener('resize', handleResize);
		}
	});

	onUnmounted(() => {
		if (typeof window !== 'undefined') {
			window.removeEventListener('resize', handleResize);
		}
	});

	return {
		device,
	};
}
